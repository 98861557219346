////  REVISIT: rendering of error messages on screen
import React, { useEffect, useState, useContext } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import DynamicFrame from "../components/common-components/DynamicFrame";
import Countdown from "../components/common-components/Countdown";
import SocketCtxtProvider from "../components/conference-components/SocketCtxtProvider";
import ConferenceSidebar from "../components/conference-components/ConferenceSidebar";
import { useWindowSize } from "../utils/Hooks";
import {
  getDailyRoom,
  getMeetingToken,
  getMeetingTokenVisitor,
} from "../functions/ConferenceFunctions";

import { useParams, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Daily from "@daily-co/daily-js";
import { DailyProvider } from "@daily-co/daily-react";

import "../App.css";

import { GlobalContext } from "../App";

export default function LiveCall() {
  ////  INITS
  const windowSize = useWindowSize();
  const GlobalCtxt = useContext(GlobalContext);
  const params = useParams();
  const navigate = useNavigate();

  ////  STATES
  const [roomData, setRoomData] = useState(null);
  const [meetingToken, setMeetingToken] = useState(null);
  const [isOwner, setIsOwner] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [dailyCall, setDailyCall] = useState(null);
  const [callActive, setCallActive] = useState(false);
  const [accessError, setAccessError] = useState(null);

  ////  FUNCTIONS
  const initRoom = async () => {
    try {
      const room = await getDailyRoom(params.roomName);
      if (room?.data) {
        setRoomData(room.data);
      }
    } catch (err) {
      console.log(err);
      setAccessError("Unable to fetch the room.");
    }
  };

  const fetchToken = async () => {
    // REVISIT: handle getMeetingTokenVisitor
    try {
      let tkn;
      if (params.code) {
        tkn = await getMeetingTokenVisitor(params.roomName, params.code);
      } else {
        tkn = await getMeetingToken(params.roomName);
      }
      if (tkn?.data) {
        const { is_owner, token, start_time } = tkn.data;
        setMeetingToken(token.token);
        setIsOwner(is_owner);
        setStartTime(start_time);
      } else {
        setAccessError("Your access token is invalid or expired.");
      }
    } catch (err) {
      console.log(err);
      setAccessError("Your access token is invalid or expired.");
    }
  };

  const initFrame = () => {
    try {
      const call = Daily.createFrame({
        iframeStyle: {
          height: "100%",
          width: "100%",
          position: "relative",
        },
        showLeaveButton: true,
        showFullscreenButton: true,
      });
      setDailyCall(call);
    } catch (err) {
      console.log(err);
      setAccessError(
        "Unable to initialise the call. Please refresh the page to try again."
      );
    }
  };

  const startCall = () => {
    try {
      setCallActive(true);
    } catch (err) {
      console.log(err);
      setAccessError(
        "Unable to initialise the call. Please refresh the page to try again."
      );
    }
  };

  ////  EFFECTS
  useEffect(() => {
    initRoom();
  }, []);

  useEffect(() => {
    if (roomData) {
      fetchToken();
    }
  }, [roomData]);

  useEffect(() => {
    if (callActive) {
      initFrame();
    }
  }, [callActive]);

  useEffect(() => {
    try {
      if (dailyCall) {
        document
          .getElementById("call-container")
          .appendChild(dailyCall.iframe());

        setTimeout(() => {
          dailyCall.join({ url: roomData.url, token: meetingToken });
        }, 1000);
      }
    } catch (err) {
      console.log(err);
    }
  }, [dailyCall]);

  return (
    <ScreenContainer styling={{ paddingTop: 0 }}>
      <DailyProvider callObject={dailyCall}>
        <DynamicFrame windowSize={windowSize} styling={{ maxWidth: "100vw" }}>
          <div
            style={{
              display: "flex",
              flex: 1,
              backgroundColor: "rgba(0,0,0,0.5)",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              padding: 8,
              boxSizing: "border-box",
            }}
          >
            {callActive ? (
              <div
                id="call-container"
                style={{ height: "100%", width: "100%" }}
              />
            ) : (
              <>
                {meetingToken ? (
                  <>
                    {startTime > Date.now() && !isOwner ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <p>This room opens in</p>
                        <Countdown deadline={startTime} />
                      </div>
                    ) : (
                      <div className="animated-border">
                        <Button variant="text" onClick={startCall}>
                          JOIN CALL
                        </Button>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {accessError ? (
                      <p style={{ color: "white" }}>{accessError}</p>
                    ) : null}
                  </>
                )}
              </>
            )}
          </div>
          {params.callType === "broadcast" ? (
            <SocketCtxtProvider
              internal={true}
              isAdmin={isOwner}
              callType={params.callType}
            />
          ) : (
            <ConferenceSidebar isAdmin={isOwner} callType={params.callType} />
          )}
        </DynamicFrame>
      </DailyProvider>
    </ScreenContainer>
  );
}
