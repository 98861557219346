//  REVISIT: meeting previews, scrollable parent div

import React, { useState, useEffect, useContext } from "react";

import LobbyWrapper from "../components/conference-components/LobbyWrapper";
import LobbyDiv from "../components/conference-components/LobbyDiv";
import MeetingPreview from "../components/conference-components/MeetingPreview";
import { getVideoEvents } from "../functions/ConferenceFunctions";

import { useParams, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

import { GlobalContext } from "../App";

export default function ConferenceLobby() {
  ////  INITS
  const params = useParams();
  const navigate = useNavigate();
  const GlobalCtxt = useContext(GlobalContext);

  ////  STATES
  const [scheduledEvents, setScheduledEvents] = useState([]);
  const [canSchedule, setCanSchedule] = useState(false);

  ////  FUNCTIONS
  const fetchEvents = async () => {
    try {
      if (params.channelId) {
        const docs = await getVideoEvents(params.channelId);
        if (Array.isArray(docs.data)) {
          setScheduledEvents(docs.data);
        }
      }
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  const checkCanSchedule = async () => {
    try {
      if (!GlobalCtxt.userInfo?.tier || GlobalCtxt.userInfo.tier <= 0) {
        return;
      } else {
        if (
          localStorage.getItem("electorate") ===
          decodeURIComponent(params.channelName)
        ) {
          setCanSchedule(true);
        } else if (
          Array.isArray(GlobalCtxt.userInfo?.secretaryOf) &&
          GlobalCtxt.userInfo.secretaryOf.includes(
            decodeURIComponent(params.channelName)
          )
        ) {
          setCanSchedule(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    fetchEvents();
    checkCanSchedule();
  }, []);

  return (
    <LobbyWrapper mode="webinar">
      <LobbyDiv>
        <h4 style={{ margin: 8 }}>{decodeURIComponent(params.channelName)}</h4>
        <h2 style={{ textAlign: "center", margin: 8 }}>Conference Room</h2>
        <hr style={{ width: "100%" }}></hr>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            flex: 1,
            overflowY: "auto",
            maxHeight: "70vh",
          }}
        >
          <p>Upcoming events:</p>
          {Array.isArray(scheduledEvents) && scheduledEvents.length > 0 ? (
            <div>
              {scheduledEvents.map((el, i) => {
                return <MeetingPreview key={el._id} data={el} />;
              })}
            </div>
          ) : (
            <p style={{ fontStyle: "italic", opacity: 0.5 }}>
              Nothing scheduled.
            </p>
          )}
        </div>
        {canSchedule ? (
          <div
            style={{
              width: "100%",
              backgroundColor: "rgba(0,0,0,0.8)",
              display: "flex",
              justifyContent: "center",
              padding: 10,
              boxSizing: "border-box",
            }}
          >
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                /*navigate(
                  `/conference-schedule/${
                    params.channelId
                  }/${decodeURIComponent(params.channelName)}`
                );*/
                alert("Video conferences are just days away!");
              }}
            >
              SCHEDULE MEETING
            </Button>
          </div>
        ) : null}
      </LobbyDiv>
    </LobbyWrapper>
  );
}

/*
import React, { useState, useEffect, useContext } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useParams, useNavigate } from "react-router-dom";

import LobbyWrapper from "../ConferenceComponents/LobbyWrapper";
import FixedModal from "../ConferenceComponents/FixedModal";
import MeetingPreview from "../ConferenceComponents/MeetingPreview";
import {
  checkToken,
  checkSec,
  checkCode,
  fetchMeetings,
} from "../utils/Functions";

import { GlobalContext } from "../App";

export default function ConferenceLobby() {
  ////  INITS
  const { _id, name } = useParams();
  const navigate = useNavigate();
  const GlobalCtxt = useContext(GlobalContext);

  ////  STATES
  const [decodedName, setDecodedName] = useState("");
  const [canSchedule, setCanSchedule] = useState(false);
  const [scheduledMeetings, setScheduledMeetings] = useState(null);
  const [paid, setPaid] = useState(false);
  const [accessCode, setAccessCode] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);

  ////  FUNCTIONS
  const checkCanSchedule = async () => {
    try {
      if (localStorage.getItem("electorate")) {
        const token = checkToken();
        if (token && token.tier > 0) {
          setPaid(true);
          if (
            token.electorate === decodeURIComponent(name) ||
            token.affiliation === decodeURIComponent(name)
          ) {
            setCanSchedule(true);
            return;
          }
        }
        const isSec = await checkSec(_id);
        if (isSec.data.isSec === true) {
          setPaid(true);
          setCanSchedule(true);
        }
      }
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  const fetchScheduledMeetings = async () => {
    try {
      const docs = await fetchMeetings(_id);
      if (docs.data.length > 0) {
        setScheduledMeetings(docs.data);
      }
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  const handleCodeSubmit = async () => {
    try {
      const codeRes = await checkCode(accessCode);
      if (codeRes.data) {
        const data = codeRes.data;
        navigate(
          `/video_call/${data.roomName}/${data.channelId}/${data.channelName}/${data.callType}/${data.code}`
        );
      } else {
        alert("Code not recognised!");
      }
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  ////  useEffects
  useEffect(() => {
    setDecodedName(decodeURIComponent(name));
    checkCanSchedule();
  }, []);

  useEffect(() => {
    if (accessCode) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [accessCode]);

  useEffect(() => {
    if (paid) {
      fetchScheduledMeetings();
    }
  }, [paid]);

  return (
    <LobbyWrapper mode="webinar">
      <FixedModal animation={true} groupName={decodedName} groupId={_id}>
        {decodedName ? (
          <>
            <h4 style={{ textAlign: "center" }}>{decodedName}</h4>
            <h2 style={{ textAlign: "center" }}>Conference Room</h2>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              {paid === true ? (
                <Button
                  size="small"
                  onClick={() => {
                    setPaid(false);
                  }}
                >
                  Use access code
                </Button>
              ) : paid === false && GlobalCtxt.globalToken?.tier > 0 ? (
                <Button
                  size="small"
                  onClick={() => {
                    setPaid(true);
                  }}
                >
                  Back
                </Button>
              ) : null}
            </div>

            <hr></hr>
          </>
        ) : null}
        {paid ? (
          <div style={{ maxHeight: "70vh", overflowY: "auto" }}>
            {scheduledMeetings ? (
              scheduledMeetings.map((el, i) => {
                return <MeetingPreview data={el} key={i} />;
              })
            ) : (
              <p style={{ fontStyle: "italic", opacity: 0.5 }}>
                Nothing scheduled
              </p>
            )}
          </div>
        ) : (
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p>Enter conference code:</p>
              <TextField
                sx={{ backgroundColor: "white", borderRadius: 2 }}
                onChange={(e) => {
                  setAccessCode(e.target.value);
                }}
              />
              <Button
                sx={{ margin: "5px" }}
                variant="outlined"
                disabled={btnDisabled}
                onClick={handleCodeSubmit}
              >
                Submit
              </Button>
              {GlobalCtxt.globalToken?.tier > 0 ? (
                <p style={{ textAlign: "center", fontWeight: "600" }}>
                  Enter the code in the field above to be redirected to your
                  video call.
                </p>
              ) : (
                <>
                  <p style={{ textAlign: "center" }}>
                    If you're not a paid subscriber, you can access the video
                    conferencing feature using a unique one-time code provided
                    by your Channel Secretary. Enter the code in the field above
                    to be redirected to your video call.
                  </p>
                  <p style={{ textAlign: "center", fontWeight: "600" }}>
                    There's no limit to the number of meetings that you can
                    schedule and participate in as a paid subscriber!{" "}
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      CLICK HERE
                    </a>{" "}
                    to gain premium access for as little as $6.90/month!
                  </p>
                </>
              )}
            </div>
          </>
        )}
        {canSchedule ? (
          <div
            style={{
              width: "100%",
              backgroundColor: "rgba(0,0,0,0.8)",
              display: "flex",
              justifyContent: "center",
              padding: 10,
              boxSizing: "border-box",
            }}
          >
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                navigate(`/conference_schedule/${_id}/${name}`);
              }}
            >
              Schedule meeting
            </Button>
          </div>
        ) : null}
      </FixedModal>
    </LobbyWrapper>
  );
}

/*import React, { useEffect, useState } from "react";


import { checkTier, fetchMeetings, checkSec } from "../utils/Functions";

export default function ConferenceLobby() {
  ////  INITS
  const { _id, name } = useParams();

  ////  STATES
  const [decodedName, setDecodedName] = useState("");
  const [renderWall, setRenderWall] = useState(null);
  const [scheduledMeetings, setScheduledMeetings] = useState(null);
  // REVERT TO FALSE
  const [isSecretary, setIsSecretary] = useState(true);

  const renderConditionally = () => {
    try {
      const tier = checkTier();
      if (tier > 0) {
        setRenderWall(false);
        const isSec = checkSec(_id);
        if (isSec === true) {
          setIsSecretary(true);
        }
      } else {
        setRenderWall(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const decoded = decodeURIComponent(name);
    setDecodedName(decoded);
    renderConditionally();
  }, []);

  useEffect(() => {
    if (renderWall !== null) {
      fetchScheduledMeetings();
    }
  }, [renderWall]);

  return (
    <>
      {renderWall === false ? (
        <FixedModal groupId={_id} groupName={decodedName}>
          <h4>{name}</h4>
        </FixedModal>
      ) : renderWall === true ? (
        <FixedModal
          groupId={_id}
          groupName={decodedName}
          isSecretary={isSecretary}
        >
          <h3 style={{ fontWeight: 400, textAlign: "center" }}>
            {decodedName}'s
          </h3>
          <h2 style={{ textAlign: "center" }}>Conference Room</h2>
          <hr style={{ width: "100%" }}></hr>
          <p>Upcoming meetings:</p>
          
        </FixedModal>
      ) : (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </>
  );
}*/
