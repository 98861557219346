import React, { useRef, useEffect, useState } from "react";
//import { useDaily } from "@daily-co/daily-react";
import AudioTest from "../../screens/AudioTest";

export default function TrackSidebarTab({ createTrack }) {
  ////  INITS
  //const daily = useDaily();
  const audioRef = useRef(null);

  ////  STATES
  const [msTrack, setMsTrack] = useState(null);

  ////  FUNCTIONS
  async function onTrackReady(mediaStream) {
    const audioTracks = mediaStream.getAudioTracks();
    const audioTrack = audioTracks[0];

    createTrack(audioTrack);
  }

  return null;
}
