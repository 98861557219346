import React from "react";

const styles = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100vw",
  minHeight: "100vh",
  overflowY: "auto",
  overflowX: "hidden",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingTop: "15vh",
  paddingBottom: "5vh",
  background: "linear-gradient(to bottom, #525854, #686E6B, #525854)",
  boxSizing: "border-box",
};

export default function ScreenContainer({ children, styling }) {
  return <div style={{ ...styles, ...styling }}>{children}</div>;
}
