import React, { useContext, useState, useEffect } from "react";

import DynamicFrame from "../common-components/DynamicFrame";
import GenericSidebar from "../common-components/GenericSidebar";
import GenericMain from "../common-components/GenericMain";
import GenericModal from "../common-components/GenericModal";
import SocketCtxtProvider from "./SocketCtxtProvider";
import { useWindowSize } from "../../utils/Hooks";
import { getUserByStreamKey } from "../../functions/StreamingFunctions";
import TipModal from "../common-components/TipModal";

import MuxPlayer from "@mux/mux-player-react";
import Button from "@mui/material/Button";
import PaidIcon from "@mui/icons-material/Paid";

import "../../App.css";

export default function StreamWindow({ streams }) {
  ////  INITS
  const windowSize = useWindowSize();

  ////  STATES
  const [currentStream, setCurrentStream] = useState(null);
  const [currentBroadcaster, setCurrentBroadcaster] = useState(null);
  const [pulsing, setPulsing] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [tipping, setTipping] = useState(false);

  ////  FUNCTIONS
  const initCurrentStream = async (strm) => {
    try {
      const user = await getUserByStreamKey(strm.stream_key);
      console.log(user);
      setCurrentBroadcaster(user.data);
      setCurrentStream(strm);
    } catch (err) {
      console.log(err);
      alert(
        "Error initialising streams! Please refresh the page to try again."
      );
    }
  };

  ////  EFFECTS
  useEffect(() => {
    if (streams) {
      console.log(streams);
    }
  }, [streams]);

  useEffect(() => {
    if (currentStream) {
      const intervalId = setInterval(() => {
        setPulsing(true);
        setTimeout(() => {
          setPulsing(false);
        }, 5000);
      }, 1000 * 60 * 5);

      return () => clearInterval(intervalId);
    }
  }, [currentStream]);

  return (
    <>
      <GenericModal open={tipping} handleClose={() => setTipping(false)}>
        <TipModal recipient={currentBroadcaster} />
      </GenericModal>
      <DynamicFrame
        windowSize={windowSize}
        styling={{
          maxWidth: "100vw",
          maxHeight: "90vh",
        }}
      >
        <GenericSidebar
          windowSize={windowSize}
          styling={{
            width: "auto",
            maxWidth: windowSize.width * 0.15,
          }}
        >
          {streams
            ? streams.map((el, i) => {
                return (
                  <div
                    key={`${i}-${el.id}`}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                      marginBottom: "8px",
                      borderRadius: "8px",
                      backgroundColor: "#333", // Dark background
                      boxShadow: "0 1px 3px rgba(0, 0, 0, 0.3)",
                      transition: "background-color 0.3s ease",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      initCurrentStream(el);
                    }}
                    onMouseEnter={
                      (e) => (e.currentTarget.style.backgroundColor = "#444") // Slightly lighter on hover
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.backgroundColor = "#333")
                    }
                  >
                    <div
                      style={{
                        height: 12,
                        width: 12,
                        borderRadius: 360,
                        backgroundColor: "limegreen",
                        marginRight: 8,
                        border: "2px solid #333", // Border matches background color
                      }}
                    />
                    <p
                      style={{
                        margin: 0,
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#FFF",
                        maxWidth: "80%",
                      }}
                    >
                      {el.passthrough}
                    </p>
                  </div>
                );
              })
            : null}
        </GenericSidebar>
        <GenericMain styling={{ padding: 0 }}>
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "black",
            }}
          >
            {Array.isArray(streams) && streams.length > 0 ? (
              <>
                {/* REVISIT: valid metadata */}
                {currentStream ? (
                  <MuxPlayer
                    playbackId={currentStream.playback_ids[0].id}
                    metadata={{
                      video_id: "video-id-54321",
                      video_title: "Test video title",
                      viewer_user_id: "user-id-007",
                    }}
                  />
                ) : (
                  <p style={{ textAlign: "center", color: "grey" }}>
                    Select a stream from the sidebar on the left.
                  </p>
                )}
              </>
            ) : (
              <p style={{ textAlign: "center", color: "grey" }}>
                Nobody is currently streaming. Please check back later.
              </p>
            )}
          </div>
          {currentStream ? (
            <div
              style={{
                width: "100%",
                backgroundColor: "black",
                padding: "10px 20px",
                boxSizing: "border-box",
                borderTop: "0.5px solid grey",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ maxWidth: "66%" }}>
                <h3 style={{ color: "white", margin: "10px 0px" }}>
                  {currentStream.passthrough}
                </h3>
                <h4
                  style={{
                    color: "lightgrey",
                    fontWeight: 400,
                    margin: "5px 0px",
                  }}
                >
                  {currentBroadcaster.username}
                </h4>
              </div>
              <div
                className={`${
                  pulsing
                    ? "animate__animated animate__tada animate__duration-4s"
                    : ""
                } ${isHovered ? "animated-border" : ""}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <Button
                  variant="contained"
                  startIcon={isHovered ? null : <PaidIcon />}
                  className="custom-button"
                  onClick={() => setTipping(true)}
                >
                  {`Tip ${currentBroadcaster.username}`}
                </Button>
              </div>
            </div>
          ) : null}
        </GenericMain>
        <GenericSidebar windowSize={windowSize}>
          {currentStream ? (
            <SocketCtxtProvider
              internal={false}
              isAdmin={false}
              stream={currentStream}
            />
          ) : null}
        </GenericSidebar>
      </DynamicFrame>
    </>
  );
}
