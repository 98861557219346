import React from "react";

import DynamicMap from "../common-components/DynamicMap";

export default function MapSidebarTab() {
  return (
    <>
      <DynamicMap />
    </>
  );
}
