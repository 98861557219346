//  REVISIT: MeetingPreview, especially JOIN button

import React, { useState, useEffect } from "react";

import { replaceAndSanitize } from "../../functions/GeneralFunctions";
import Countdown from "../common-components/Countdown";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import GroupsIcon from "@mui/icons-material/Groups";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import SchoolIcon from "@mui/icons-material/School";

export default function MeetingPreview({ data }) {
  ////  INITS
  const navigate = useNavigate();

  ////  STATES
  const [timeStr, setTimeStr] = useState("");
  const [modStr, setModStr] = useState("");
  const [meetingLive, setMeetingLive] = useState(false);

  ////  FUNCTIONS
  const initStrings = () => {
    try {
      if (data.time) {
        const toDate = new Date(data.time);
        const str = toDate.toLocaleString();
        setTimeStr(str);
        if (data.time <= Date.now()) {
          setMeetingLive(true);
        }
      }
      if (data.moderators) {
        let mods = "";
        if (data.moderators.length === 1) {
          mods = data.moderators[0].username;
        } else {
          mods = data.moderators.map((el) => el.username).join(", ");
        }
        setModStr(mods);
      }
    } catch (err) {
      console.log(err);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    initStrings();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "whitesmoke",
        border: "1px solid black",
        borderRadius: 4,
        padding: 8,
        boxSizing: "border-box",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        marginBottom: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <CalendarMonthIcon size="small" sx={{ marginRight: "8px" }} />
          <p style={{ fontWeight: 500, fontSize: 14 }}>{timeStr}</p>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p style={{ marginRight: 8, color: "grey", fontSize: 14 }}>
            {data.callType.toUpperCase()}
          </p>
          {data.callType === "meeting" ? (
            <GroupsIcon />
          ) : data.callType === "broadcast" ? (
            <PodcastsIcon />
          ) : (
            <SchoolIcon />
          )}
        </div>
      </div>
      <hr style={{ width: "100%" }}></hr>
      <p style={{ marginBottom: 0 }}>
        Event:{" "}
        <span style={{ fontWeight: 600 }}>
          {replaceAndSanitize(data.eventName)}
        </span>
      </p>
      {data.duration === 21600000 ? (
        <p style={{ marginBottom: 0 }}>
          Duration: <span style={{ fontWeight: 600 }}>6 hours</span>
        </p>
      ) : !isNaN(data.duration) && data.duration < 21600000 ? (
        <p style={{ marginBottom: 0 }}>
          Duration:{" "}
          <span style={{ fontWeight: 600 }}>
            {parseInt(data.duration) / 60000} minutes
          </span>
        </p>
      ) : null}
      <p style={{ marginBottom: 0 }}>
        Hosted by: <span style={{ fontWeight: 600 }}>{modStr}</span>
      </p>
      <br></br>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        {!meetingLive ? (
          <Button
            variant="contained"
            onClick={() => {
              navigate(
                `/live-call/${data.roomName}/${data.channelId}/${data.channelName}/${data.callType}`
              );
            }}
          >
            JOIN!
          </Button>
        ) : (
          <>
            {data.time - Date.now() > 86400000 ? (
              <p style={{ opacity: 0.5 }}>SCHEDULED</p>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  opacity: 0.5,
                }}
              >
                <p style={{ marginRight: 5 }}>Beginning in</p>
                <Countdown deadline={data.time} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
