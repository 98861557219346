import React, { useState, useEffect } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";
import { centsToDollars } from "../functions/GeneralFunctions";

import TextField from "@mui/material/TextField";

const tierRef = [468, 690, 1190, 1490, 3900];

export default function PricingTest() {
  ////  STATES
  const [totalUsers, setTotalUsers] = useState(0);
  const [userPercentages, setUserPercentages] = useState({
    t0: 0,
    t1: 0,
    t2: 0,
    t3: 0,
    t4: 0,
  });
  const [usersByTier, setUsersByTier] = useState({
    t0: 0,
    t1: 0,
    t2: 0,
    t3: 0,
    t4: 0,
  });
  const [returnByTier, setReturnByTier] = useState({
    t0: 0,
    t1: 0,
    t2: 0,
    t3: 0,
    t4: 0,
  });

  ////  FUNCTIONS
  const getGP = (value, index) => {
    if (index === 0) {
      return centsToDollars(value);
    }
    const gst = value / 11;
    const stripeFee = 30 + value * 0.029;
    let grossProfit = value - gst - stripeFee;
    if (index >= 2) {
      grossProfit -= 300;
    }
    return centsToDollars(grossProfit);
  };

  ////  EFFECTS
  useEffect(() => {
    setUsersByTier({
      t0: userPercentages.t0 * totalUsers,
      t1: userPercentages.t1 * totalUsers,
      t2: userPercentages.t2 * totalUsers,
      t3: userPercentages.t3 * totalUsers,
      t4: userPercentages.t4 * totalUsers,
    });
  }, [userPercentages]);

  useEffect(() => {
    const newT0 = usersByTier.t0 * tierRef[0];
    const newT1 = usersByTier.t1 * tierRef[1];
    const newT2 = usersByTier.t2 * tierRef[2];
    const newT3 = usersByTier.t3 * tierRef[3];
    const newT4 = usersByTier.t4 * tierRef[4];
    setReturnByTier({
      t0: newT0,
      t1: newT1,
      t2: newT2,
      t3: newT3,
      t4: newT4,
    });
  }, [usersByTier]);

  return (
    <ScreenContainer>
      <Navbar />
      <div
        style={{
          width: "100vw",
          maxWidth: 900,
          backgroundColor: "whitesmoke",
          padding: 10,
          boxSizing: "border-box",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TextField
          type="number"
          placeholder="Number of users"
          sx={{ minWidth: 425 }}
          onChange={(e) => {
            setTotalUsers(parseInt(e.target.value));
          }}
        />
        <br></br>
        <table
          style={{ width: "100%", margin: "20px 0px", textAlign: "center" }}
        >
          <tr>
            <th>Tier</th>
            <th>Price</th>
            <th>Gross profit</th>
            <th>Users (% of total)</th>
            <th>Users</th>
            <th>Total gross profit</th>
          </tr>
          {tierRef.map((el, i) => {
            return (
              <tr>
                <td>{i}</td>
                <td>{centsToDollars(el)}</td>
                <td>{getGP(el, i)}</td>
                <td>
                  <TextField
                    type="number"
                    onChange={(e) => {
                      setUserPercentages({
                        ...userPercentages,
                        [`t${i}`]: parseInt(e.target.value) / 100,
                      });
                    }}
                  />
                </td>
                <td>{usersByTier[`t${i}`]}</td>
                <td>{centsToDollars(returnByTier[`t${i}`])}</td>
              </tr>
            );
          })}
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              {usersByTier.t0 +
                usersByTier.t1 +
                usersByTier.t2 +
                usersByTier.t3 +
                usersByTier.t4}
            </td>
            <td>
              {centsToDollars(
                returnByTier.t0 +
                  returnByTier.t1 +
                  returnByTier.t2 +
                  returnByTier.t3 +
                  returnByTier.t4
              )}
            </td>
          </tr>
        </table>
      </div>
    </ScreenContainer>
  );
  /*return (
    <ScreenContainer>
      <Navbar />
      <div
        style={{
          width: "100vw",
          maxWidth: 768,
          backgroundColor: "whitesmoke",
          padding: 10,
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TextField type="number"
            type="number"
            placeholder="Number of users"
            onChange={(e) => {
              setTotalUsers(parseInt(e.target.value));
            }}
          />
        </div>
        <br></br>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-evenly",
          }}
        >
          <h4>FREE</h4>
          <p>$0.00</p>
          <p>$4.32</p>
          <TextField type="number"
            type="number"
            size="small"
            onChange={(e) => {
              setUsersByTier({
                ...usersByTier,
                t0: totalUsers * (e.target.value / 100),
              });
            }}
          />
          <p>{usersByTier.t0}</p>
          <h4>{centsToDollars(returnByTier.t0)}</h4>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-evenly",
          }}
        >
          <h4>Tier #1</h4>
          <p>$6.90</p>
          <p>$5.77</p>
          <TextField type="number"
            type="number"
            size="small"
            onChange={(e) => {
              setUsersByTier({
                ...usersByTier,
                t1: totalUsers * (e.target.value / 100),
              });
            }}
          />
          <p>{usersByTier.t1}</p>
          <h4>{centsToDollars(returnByTier.t1)}</h4>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-evenly",
          }}
        >
          <h4>Tier #2</h4>
          <p>$11.90</p>
          <p>$7.17</p>
          <TextField type="number"
            type="number"
            size="small"
            onChange={(e) => {
              setUsersByTier({
                ...usersByTier,
                t2: totalUsers * (e.target.value / 100),
              });
            }}
          />
          <p>{usersByTier.t2}</p>
          <h4>{centsToDollars(returnByTier.t2)}</h4>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-evenly",
          }}
        >
          <h4>Tier #3</h4>
          <p>$14.90</p>
          <p>$9.81</p>
          <TextField type="number"
            type="number"
            size="small"
            onChange={(e) => {
              setUsersByTier({
                ...usersByTier,
                t3: totalUsers * (e.target.value / 100),
              });
            }}
          />
          <p>{usersByTier.t3}</p>
          <h4>{centsToDollars(returnByTier.t3)}</h4>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-evenly",
          }}
        >
          <h4>Tier #4</h4>
          <p>$39.00</p>
          <p>$31.00</p>
          <TextField type="number"
            type="number"
            size="small"
            onChange={(e) => {
              setUsersByTier({
                ...usersByTier,
                t4: totalUsers * (e.target.value / 100),
              });
            }}
          />
          <p>{usersByTier.t4}</p>
          <h4>{centsToDollars(returnByTier.t4)}</h4>
        </div>
        <br></br>
        <p>
          %:{" "}
          {((usersByTier.t0 +
            usersByTier.t1 +
            usersByTier.t2 +
            usersByTier.t3 +
            usersByTier.t4) /
            totalUsers) *
            100}
        </p>
        <h4>
          Monthly revenue:{" "}
          {centsToDollars(
            returnByTier.t0 +
              returnByTier.t1 +
              returnByTier.t2 +
              returnByTier.t3 +
              returnByTier.t4
          )}
        </h4>
      </div>
    </ScreenContainer>
  );*/
}
