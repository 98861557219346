import authenticatedConfig from "../utils/Token";
import Axios from "axios";
//import Cookies from "js-cookie";

const createVideoEvent = async (data) => {
  try {
    const doc = await Axios.post(
      `${process.env.REACT_APP_DEV_SERVER}/video/create-video-event`,
      data,
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const createDailyRoom = async (data) => {
  try {
    const doc = await Axios.post(
      `${process.env.REACT_APP_DEV_SERVER}/video/create-daily-room`,
      data,
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getVideoEvents = async (channelId) => {
  try {
    const docs = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/video/get-video-events?channelId=${channelId}`
    );
    return docs;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getDailyRoom = async (roomName) => {
  try {
    const doc = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/video/get-daily-room?roomName=${roomName}`,
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getMeetingToken = async (roomName) => {
  try {
    const doc = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/video/get-meeting-token?roomName=${roomName}`,
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getMeetingTokenVisitor = async (roomName, code) => {
  try {
    const doc = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/video/get-meeting-token-visitor?roomName=${roomName}&code=${code}`
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const createMeetingCode = async (data) => {
  try {
    const doc = await Axios.post(
      `${process.env.REACT_APP_DEV_SERVER}/video/create-meeting-code`,
      data,
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const changeEventPrivacy = async (data) => {
  try {
    const doc = await Axios.put(
      `${process.env.REACT_APP_DEV_SERVER}/video/change-event-privacy`,
      data,
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

export {
  createVideoEvent,
  createDailyRoom,
  getVideoEvents,
  getDailyRoom,
  getMeetingToken,
  getMeetingTokenVisitor,
  createMeetingCode,
  changeEventPrivacy,
};
