const electorateList = [
  "Adelaide",
  "Aston",
  "Ballarat",
  "Banks",
  "Barker",
  "Barton",
  "Bass",
  "Bean",
  "Bendigo",
  "Bennelong",
  "Berowra",
  "Blair",
  "Blaxland",
  "Bonner",
  "Boothby",
  "Bowman",
  "Braddon",
  "Bradfield",
  "Brand",
  "Brisbane",
  "Bruce",
  "Burt",
  "Calare",
  "Calwell",
  "Canberra",
  "Canning",
  "Capricornia",
  "Casey",
  "Chifley",
  "Chisholm",
  "Clark",
  "Cook",
  "Cooper",
  "Corangamite",
  "Corio",
  "Cowan",
  "Cowper",
  "Cunningham",
  "Curtin",
  "Dawson",
  "Deakin",
  "Dickson",
  "Dobell",
  "Dunkley",
  "Durack",
  "Eden-Monaro",
  "Fadden",
  "Fairfax",
  "Farrer",
  "Fenner",
  "Fisher",
  "Flinders",
  "Flynn",
  "Forde",
  "Forrest",
  "Fowler",
  "Franklin",
  "Fraser",
  "Fremantle",
  "Gellibrand",
  "Gilmore",
  "Gippsland",
  "Goldstein",
  "Gorton",
  "Grayndler",
  "Greenway",
  "Grey",
  "Griffith",
  "Groom",
  "Hasluck",
  "Hawke",
  "Herbert",
  "Higgins",
  "Hindmarsh",
  "Hinkler",
  "Holt",
  "Hotham",
  "Hughes",
  "Hume",
  "Hunter",
  "Indi",
  "Isaacs",
  "Jagajaga",
  "Kennedy",
  "Kingsford Smith",
  "Kingston",
  "Kooyong",
  "La Trobe",
  "Lalor",
  "Leichhardt",
  "Lilley",
  "Lindsay",
  "Lingiari",
  "Longman",
  "Lyne",
  "Lyons",
  "Macarthur",
  "Mackellar",
  "Macnamara",
  "Macquarie",
  "Makin",
  "Mallee",
  "Maranoa",
  "Maribyrnong",
  "Mayo",
  "McEwen",
  "McMahon",
  "McPherson",
  "Melbourne",
  "Menzies",
  "Mitchell",
  "Monash",
  "Moncrieff",
  "Moore",
  "Moreton",
  "New England",
  "Newcastle",
  "Nicholls",
  "North Sydney",
  "O'Connor",
  "Oxley",
  "Page",
  "Parkes",
  "Parramatta",
  "Paterson",
  "Pearce",
  "Perth",
  "Petrie",
  "Rankin",
  "Reid",
  "Richmond",
  "Riverina",
  "Robertson",
  "Ryan",
  "Scullin",
  "Shortland",
  "Solomon",
  "Spence",
  "Sturt",
  "Swan",
  "Sydney",
  "Tangney",
  "Wannon",
  "Warringah",
  "Watson",
  "Wentworth",
  "Werriwa",
  "Whitlam",
  "Wide Bay",
  "Wills",
  "Wright",
];

const loremIpsum = {
  lorem_25:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tincidunt justo non nisl aliquet, sed scelerisque risus auctor. Aliquam erat volutpat.",
  lorem_50:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus euismod, libero id ultrices suscipit, lectus eros vehicula quam, nec auctor nisl lorem a odio. Nam sit amet turpis id enim ullamcorper varius. Aliquam erat volutpat. Donec tincidunt, nisl sit amet commodo fermentum, urna velit.",
  lorem_75:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus euismod, libero id ultrices suscipit, lectus eros vehicula quam, nec auctor nisl lorem a odio. Nam sit amet turpis id enim ullamcorper varius. Aliquam erat volutpat. Donec tincidunt, nisl sit amet commodo fermentum, urna velit volutpat dolor, quis blandit velit mi eget quam. Cras sollicitudin, mauris a suscipit luctus, justo ligula lacinia eros, ut malesuada velit turpis id risus.",
  lorem_100:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus euismod, libero id ultrices suscipit, lectus eros vehicula quam, nec auctor nisl lorem a odio. Nam sit amet turpis id enim ullamcorper varius. Aliquam erat volutpat. Donec tincidunt, nisl sit amet commodo fermentum, urna velit volutpat dolor, quis blandit velit mi eget quam. Cras sollicitudin, mauris a suscipit luctus, justo ligula lacinia eros, ut malesuada velit turpis id risus. Nullam ut tortor neque. Curabitur faucibus euismod velit, ac tempor justo fermentum sit amet. Quisque ac dolor vel mauris eleifend ultricies.",
};

const acceptableImageFormats = [
  "image/webp",
  "image/svg",
  "image/png",
  "image/jpg",
  "image/jpeg",
];

const acceptableFormats = [
  "image/webp",
  "image/svg",
  "image/png",
  "image/jpg",
  "image/jpeg",
  "application/pdf",
];

const subOptions = [
  {
    label: "Standard",
    price: 690,
    price_str: "6.90",
    lookup_key: "pc_membership_t1",
    tier: 1,
    perks: [
      {
        perk: "Minimal ads",
        explainer: null,
      },
      {
        perk: "Nominate for Channel Secretary",
        explainer: null,
      },
      {
        perk: "Vote for Channel Secretary",
        explainer:
          "Vote for who becomes Secretary of your Channel, the National Channel, and beyond!",
      },
      {
        perk: "Members' Advantage",
        explainer:
          "Automatic tiebreak advantage over lower tier candidates when running for Channel Secretary.",
      },
      {
        perk: "Host and attend video calls",
        explainer: null,
      },
      {
        perk: "Unlimited NewsChute drops",
        explainer: null,
      },
    ],
  },
  {
    label: "Investor",
    price: 1190,
    price_str: "11.90",
    lookup_key: "pc_membership_t2",
    tier: 2,
    perks: [
      {
        perk: "Minimal ads",
        explainer: null,
      },
      {
        perk: "Nominate for Channel Secretary",
        explainer: null,
      },
      {
        perk: "Vote for Channel Secretary",
        explainer:
          "Vote for who becomes Secretary of your Channel, the National Channel, and beyond!",
      },
      {
        perk: "Members' Advantage",
        explainer:
          "Automatic tiebreak advantage over lower tier candidates when running for Channel Secretary.",
      },
      {
        perk: "Host and attend video calls",
        explainer: null,
      },
      {
        perk: "Unlimited NewsChute drops",
        explainer: null,
      },
      {
        perk: "Community Reinvestment",
        explainer:
          "$3 of your monthly subscription fee will be reinvested into your electorate's Community Balance.",
      },
      {
        perk: "Vote on funding proposals",
        explainer:
          "As a community investor, you will have the power to vote for or against funding proposals that are put to your local Channel.",
      },
      {
        perk: "Create your own Channel",
        explainer: null,
      },
      {
        perk: "Host private video calls",
        explainer:
          "Host private video calls through your Channel's own Conference Room feature",
      },
      {
        perk: "Launch fundraisers",
        explainer: null,
      },
      {
        perk: "15% withdrawal fee",
        explainer: "You keep 85% of funds raised through this platform.",
      },
    ],
  },
  {
    label: "Investor Plus",
    price: 1490,
    price_str: "14.90",
    lookup_key: "pc_membership_t3",
    tier: 3,
    perks: [
      {
        perk: "Minimal ads",
        explainer: null,
      },
      {
        perk: "Nominate for Channel Secretary",
        explainer: null,
      },
      {
        perk: "Vote for Channel Secretary",
        explainer:
          "Vote for who becomes Secretary of your Channel, the National Channel, and beyond!",
      },
      {
        perk: "Members' Advantage",
        explainer:
          "Automatic tiebreak advantage over lower tier candidates when running for Channel Secretary.",
      },
      {
        perk: "Host and attend video conferences",
        explainer: null,
      },
      {
        perk: "Unlimited NewsChute drops",
        explainer: null,
      },
      {
        perk: "Community Reinvestment",
        explainer:
          "$3 of your monthly subscription fee will be reinvested into your electorate's Community Balance.",
      },
      {
        perk: "Vote on funding proposals",
        explainer:
          "As a community investor, you will have the power to vote for or against funding proposals that are put to your local Channel.",
      },
      {
        perk: "Create your own Channel",
        explainer: null,
      },
      {
        perk: "Host private video calls",
        explainer:
          "Host private video calls through your Channel's own Conference Room feature.",
      },
      {
        perk: "Launch fundraisers",
        explainer: null,
      },
      {
        perk: "10% withdrawal fee",
        explainer: "You keep 90% of funds raised through this platform.",
      },
      {
        perk: "NewsChute AI summaries",
        explainer:
          "Instantly access AI-generated summaries of embedded NewsChute content.",
      },
    ],
  },
  {
    label: "Community Leader",
    price: 3500,
    price_str: "35.00",
    lookup_key: "pc_membership_t4",
    tier: 4,
    perks: [
      {
        perk: "Zero ads",
        explainer: null,
      },
      {
        perk: "Nominate for Channel Secretary",
        explainer: null,
      },
      {
        perk: "Vote for Channel Secretary",
        explainer:
          "Vote for who becomes Secretary of your Channel, the National Channel, and beyond!",
      },
      {
        perk: "Members' Advantage",
        explainer:
          "Automatic tiebreak advantage over lower tier candidates when running for Channel Secretary.",
      },
      {
        perk: "Host and attend video conferences",
        explainer: null,
      },
      {
        perk: "Unlimited NewsChute drops",
        explainer: null,
      },
      {
        perk: "Community Reinvestment",
        explainer:
          "$3 of your monthly subscription fee will be reinvested into your electorate's Community Balance.",
      },
      {
        perk: "Vote on funding proposals",
        explainer:
          "As a community investor, you will have the power to vote for or against funding proposals that are put to your local Channel.",
      },
      {
        perk: "Create your own Channel",
        explainer: null,
      },
      {
        perk: "Host private video calls",
        explainer:
          "Host private video calls through your Channel's own Conference Room feature.",
      },
      {
        perk: "Launch fundraisers",
        explainer: null,
      },
      {
        perk: "2.5% withdrawal fee",
        explainer: "You keep 97.5% of funds raised through this platform.",
      },
      {
        perk: "NewsChute AI summaries",
        explainer:
          "Instantly access AI-generated summaries of embedded NewsChute content.",
      },
      {
        perk: "Launch your own broadcast",
        explainer: null,
      },
      {
        perk: "Live stream to multiple platforms",
        explainer: null,
      },
      {
        perk: "Download and publish recordings",
        explainer: null,
      },
    ],
  },
];

const dummyStreams = [];

export {
  electorateList,
  loremIpsum,
  acceptableFormats,
  acceptableImageFormats,
  subOptions,
  dummyStreams,
};
