import React, { useEffect, useContext } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";

import { useNavigate } from "react-router-dom";

import { GlobalContext } from "../App";

export default function Landing() {
  const navigate = useNavigate();
  const GlobalCtxt = useContext(GlobalContext);

  useEffect(() => {
    navigate(`/home/${GlobalCtxt.nationalId}/National`);
  }, []);

  return (
    <ScreenContainer>
      <Navbar />
    </ScreenContainer>
  );
}
