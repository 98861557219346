import React, { useState, useEffect } from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";
import { findElectorateFromPostcode } from "../functions/GeneralFunctions";
import { createWebinarRegistration } from "../functions/UserFunctions";
import { useWindowSize } from "../utils/Hooks";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import validator from "email-validator";
import { useNavigate } from "react-router-dom";

export default function FlyerLanding() {
  ////  INITS
  const navigate = useNavigate();
  const logoUrls = [
    "https://storage.googleapis.com/bucket-775/WSI_logo_800x800.png",
    "https://storage.googleapis.com/bucket-775/amps_logo.png",
    "https://storage.googleapis.com/bucket-775/ausexits_logo.png",
  ];
  const windowSize = useWindowSize();

  ////  STATES
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [electorate, setElectorate] = useState(null);
  const [electorateOptions, setElectorateOptions] = useState(null);
  const [electorateConfirmed, setElectorateConfirmed] = useState(false);
  const [postcode, setPostcode] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);

  ////  FUNCTIONS
  const fetchElectorate = async () => {
    try {
      setElectorate("Fetching ...");
      const elec = await findElectorateFromPostcode(postcode);
      if (Array.isArray(elec)) {
        if (elec.length === 1) {
          setElectorate(elec[0].name);
        } else {
          const options = elec.map((option) => option.name);
          setElectorateOptions(options);
          setElectorate(null);
        }
      } else {
        setElectorate(null);
      }
    } catch (err) {
      console.log(err);
      setElectorate(null);
    }
  };

  const validateInputs = () => {
    try {
      if (!email || email.length <= 0) {
        alert("Please complete the email field.");
        return false;
      }
      const emailValid = validator.validate(email.trim());
      if (!emailValid) {
        alert("Please enter a valid email.");
        return false;
      }
      if (!postcode || postcode.length <= 2 || postcode.length > 4) {
        alert("Please enter a valid postcode.");
        return false;
      }
      let postcodeValid = parseInt(postcode);
      if (isNaN(postcodeValid)) {
        alert("Please enter a valid postcode.");
        return false;
      }
      return true;
    } catch (err) {
      console.log(err);
      alert("Error processing inputs!");
      return false;
    }
  };

  const handleSubmit = async () => {
    try {
      setBtnDisabled(true);
      const canProceed = validateInputs();
      if (canProceed) {
        await createWebinarRegistration({
          fName: !fName ? null : fName.trim(),
          lName: !lName ? null : lName.trim(),
          email: email.trim(),
          postcode: postcode.trim(),
          electorate: electorateConfirmed ? electorate : null,
          phone: !phone ? null : phone.trim(),
        });
        setTimeout(() => {
          navigate("/flyer-success");
        }, 500);
      } else {
        setBtnDisabled(false);
      }
    } catch (err) {
      console.log(err);
      alert("Error submitting inputs! Please refresh the page to try again.");
      setBtnDisabled(false);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    if (postcode && postcode.length === 4) {
      fetchElectorate();
    }
    if (postcode && postcode.length <= 3) {
      setElectorate(null);
      setElectorateOptions(null);
    }
  }, [postcode]);

  useEffect(() => {
    if (!electorate) {
      setElectorateConfirmed(false);
    }
  }, [electorate]);

  return (
    <ScreenContainer>
      <Navbar />
      <div
        style={{
          width: "100vw",
          maxWidth: 768,
          backgroundColor: "whitesmoke",
          borderRadius: 4,
          border: "1px solid black",
          padding: 10,
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h3 style={{ textAlign: "center" }}>Webinar registration form</h3>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            marginTop: "10px",
            marginBottom: "20px",
          }}
        >
          {logoUrls.map((el, i) => {
            return (
              <img
                src={el}
                key={i}
                style={{
                  maxHeight: windowSize.width <= 768 ? "75px" : "100px",
                  width: "auto",
                  borderRadius: 4,
                  border: "0.5px solid grey",
                }}
              />
            );
          })}
        </div>

        <div
          style={{
            width: "100%",
            backgroundColor: "lightgrey",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <p style={{ textAlign: "center", maxWidth: 650 }}>
            You're invited to join the{" "}
            <span style={{ fontWeight: 600 }}>Winston Smith Initiative</span>,{" "}
            <span style={{ fontWeight: 600 }}>AMPS</span> and{" "}
            <span style={{ fontWeight: 600 }}>AusExits</span> in launching the{" "}
            <span style={{ fontWeight: "bold", color: "red" }}>
              We Say NO! campaign.
            </span>{" "}
            Enter your details below so that we can work together to prevent
            further lockdowns, mandates and erosion of our civil liberties.
          </p>
        </div>

        <br></br>
        <div style={{ width: "100%" }}>
          <h4>Webinar details:</h4>
          <p>Wednesday 25 September 2024 @ 8pm AEST</p>
          <p style={{ fontSize: 14, fontStyle: "italic" }}>
            <span style={{ fontWeight: 600 }}>NOTE:</span> A webinar link will
            be emailed to you roughly 2 hours before the webinar begins.
          </p>
        </div>
        <hr style={{ width: "100%" }}></hr>
        <p style={{ fontSize: 14, opacity: 0.5 }}>
          Fields marked with an asterisk are required.
        </p>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <TextField
            placeholder="First name"
            fullWidth={true}
            sx={{ margin: "5px" }}
            onChange={(e) => {
              setFName(e.target.value);
            }}
          />
          <TextField
            placeholder="Last name"
            fullWidth={true}
            sx={{ margin: "5px" }}
            onChange={(e) => {
              setLName(e.target.value);
            }}
          />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <TextField
            placeholder="Phone number"
            fullWidth={true}
            sx={{ margin: "5px" }}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          />
          <TextField
            placeholder="Email *"
            fullWidth={true}
            sx={{ margin: "5px" }}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div style={{ width: "100%" }}>
          <TextField
            placeholder="Postcode *"
            sx={{ margin: "5px" }}
            onChange={(e) => {
              setPostcode(e.target.value);
            }}
          />
        </div>

        {electorate ? (
          <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
            <p
              style={{
                margin: "5px 40px 5px 5px",
                opacity: electorateConfirmed ? 1 : 0.3,
              }}
            >
              Electorate: <span style={{ fontWeight: 600 }}>{electorate}</span>
            </p>
            <div style={{ display: "flex" }}>
              <Checkbox
                checked={electorateConfirmed}
                onChange={(e) => {
                  setElectorateConfirmed(e.target.checked);
                }}
              />
              <p style={{ fontSize: 14, fontWeight: 300, opacity: 0.75 }}>
                Confirm electorate
              </p>
            </div>
          </div>
        ) : (
          <>
            {Array.isArray(electorateOptions) ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {electorateOptions.map((el, i) => {
                  return (
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ margin: "5px" }}
                      onClick={() => {
                        setElectorate(el);
                      }}
                    >
                      {el}
                    </Button>
                  );
                })}
              </div>
            ) : null}
          </>
        )}
        <hr style={{ width: "100%" }}></hr>
        <br></br>
        <Button
          variant="contained"
          sx={{ marginTop: "10px" }}
          onClick={handleSubmit}
          disabled={btnDisabled}
        >
          SUBMIT!
        </Button>
      </div>
    </ScreenContainer>
  );
}
