import Axios from "axios";
import authenticatedConfig from "../utils/Token";

const getUserById = async (userId) => {
  try {
    const doc = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/users/get-user-by-id?userId=${userId}`
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getNotifications = async () => {
  try {
    const docs = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/users/get-notifications`,
      authenticatedConfig
    );
    return docs;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

function batchNotifications(notifications, lastFetch) {
  try {
    const batched = {};

    notifications.forEach((notification) => {
      const key = `${notification.type}-${notification.targetId}`;

      if (!batched[key]) {
        batched[key] = {
          type: notification.type,
          targetId: notification.targetId,
          users: new Set(),
          highlight: false,
          createdAt: notification.createdAt,
        };
      }

      batched[key].users.add(notification.fromUsername);
      if (notification.createdAt > lastFetch) {
        batched[key].highlight = true;
      }
      if (notification.createdAt > batched[key].createdAt) {
        batched[key].createdAt = notification.createdAt;
      }
    });

    const result = Object.keys(batched).map((key) => {
      const { type, targetId, users, highlight, createdAt } = batched[key];
      const userCount = users.size;
      const usersArray = [...users];

      let msg;
      if (type === "like-post") {
        if (userCount === 1) {
          msg = `${usersArray[0]} liked your post`;
        } else {
          msg = `${userCount} users liked your post`;
        }
      } else if (type === "like-comment") {
        if (userCount === 1) {
          msg = `${usersArray[0]} liked your comment`;
        } else {
          msg = `${userCount} users liked your comment`;
        }
      } else if (type === "like-video") {
        if (userCount === 1) {
          msg = `${usersArray[0]} liked your video`;
        } else {
          msg = `${userCount} users liked your video`;
        }
      } else if (type === "like-doc") {
        if (userCount === 1) {
          msg = `${usersArray[0]} liked your NewsChute document`;
        } else {
          msg = `${userCount} users liked your NewsChute document`;
        }
      } else if (type === "comment-post") {
        if (userCount === 1) {
          msg = `${usersArray[0]} commented on your post`;
        } else {
          msg = `${userCount} users commented on your post`;
        }
      } else if (type === "chat") {
        if (userCount === 1) {
          msg = `${usersArray[0]} sent you a message`;
        } else {
          msg = `${userCount} users sent you a message`;
        }
      } else {
        if (userCount === 1) {
          msg = `${usersArray[0]} left a comment`;
        } else {
          msg = `${userCount} users left a comment`;
        }
      }

      return {
        msg,
        targetId,
        type,
        highlight,
        createdAt,
      };
    });

    return result;
  } catch (err) {
    console.log(err);
    throw new Error("Error retrieving notifications!");
  }
}

const postPublicKey = async (publicKey) => {
  try {
    const doc = await Axios.put(
      `${process.env.REACT_APP_DEV_SERVER}/users/post-public-key`,
      { publicKey },
      authenticatedConfig
    );
    return doc;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const createWebinarRegistration = async (data) => {
  try {
    const doc = await Axios.post(
      `${process.env.REACT_APP_DEV_SERVER}/flyers/create-webinar-registration`,
      data
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const addToMailingList = async (data) => {
  try {
    const doc = await Axios.post(
      `${process.env.REACT_APP_DEV_SERVER}/flyers/add-to-mailing-list`,
      data
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const getVolunteers = async (password) => {
  try {
    const docs = await Axios.get(
      `${process.env.REACT_APP_DEV_SERVER}/flyers/get-volunteers?password=${password}`
    );
    return docs;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

export {
  getUserById,
  getNotifications,
  batchNotifications,
  postPublicKey,
  createWebinarRegistration,
  getVolunteers,
  addToMailingList,
};
