import Axios from "axios";
import authenticatedConfig from "../utils/Token";

const sendMarkerAuthenticationEmail = async (data) => {
  try {
    const doc = await Axios.post(
      `${process.env.REACT_APP_MAILING_SERVER}/email/verify-marker`,
      data
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

const sendCustomisedLetter = async (data) => {
  try {
    const doc = await Axios.post(
      `${process.env.REACT_APP_MAILING_SERVER}/email/send-customised-letter`,
      data
    );
    return doc;
  } catch (err) {
    console.log(err);
    if (err.response?.data) {
      throw new Error(err.response.data);
    } else {
      throw new Error(err.message);
    }
  }
};

export { sendMarkerAuthenticationEmail, sendCustomisedLetter };
