import React, { useContext } from "react";

import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";

import { GlobalContext } from "../../App";

export default function LandingSidebarTab({ meetingActive, toggleSidebar }) {
  ////  INITS
  const navigate = useNavigate();
  const GlobalCtxt = useContext(GlobalContext);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <>
        {meetingActive ? (
          <Button
            color="info"
            variant="contained"
            size="small"
            onClick={toggleSidebar}
            sx={{ width: "100%" }}
          >
            CLOSE SIDEBAR
          </Button>
        ) : (
          <div style={{ width: "100%" }}>
            <p style={{ textAlign: "center" }}>
              Click JOIN CALL to get started!
            </p>
            <hr style={{ width: "100%" }}></hr>
          </div>
        )}
      </>
      <Button
        variant="outlined"
        onClick={() => {
          navigate(`/home/${GlobalCtxt.nationalId}/National`);
        }}
      >
        RETURN TO HOMEPAGE
      </Button>
    </div>
  );
}
