import React from "react";

import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";

export default function WeSayNo() {
  return (
    <ScreenContainer>
      <Navbar />
      <h4>placeholder text</h4>
    </ScreenContainer>
  );
}
