import React, { useState, useEffect } from "react";

import {
  Marker,
  MarkerClusterer,
  GoogleMap,
  Circle,
  InfoWindow,
} from "@react-google-maps/api";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import CircularProgress from "@mui/material/CircularProgress";
import DOMPurify from "dompurify";

import GenericTextField from "./GenericTextField";
import { getMarkers, createMapMarker } from "../../functions/ChannelFunctions";
import { replaceAndSanitize } from "../../functions/GeneralFunctions";

const defaultContainerStyling = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};
const defaultMapStyling = {
  width: "100%",
  aspectRatio: "1 / 1",
  maxHeight: "50vh",
};

export default function DynamicMap({
  coords,
  channelId,
  channelName,
  displayOnly,
  containerStyling,
  mapStyling,
  handleSubmit,
  handleMarkerClick,
  category,
  acceptNotes,
  maxRadius,
  markerClasses,
  reloadMap,
}) {
  ////  STATES
  const [markers, setMarkers] = useState(null);
  const [mapCenter, setMapCenter] = useState(null);
  const [mapZoom, setMapZoom] = useState(4);
  const [mapReady, setMapReady] = useState(false);
  const [noteAdded, setNoteAdded] = useState(false);
  const [pinPosition, setPinPosition] = useState(null);
  const [bubbleRadius, setBubbleRadius] = useState(null);
  const [selectedClass, setSelectedClass] = useState("");
  const [note, setNote] = useState("");
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [inputsDisabled, setInputsDisabled] = useState(false);
  const [markerUploaded, setMarkerUploaded] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const [maxRadiusLocal, setMaxRadiusLocal] = useState(500000);

  ////  FUNCTIONS
  const initMap = async () => {
    try {
      if (displayOnly) {
        return;
      }
      setSelectedMarker(null);
      const mrks = await getMarkers(channelId, selectedClass);
      setMarkers(mrks.data);
      setPinPosition(null);
    } catch (err) {
      console.log(err);
      setLoadError(true);
    }
  };

  const handleBubbleChange = (e) => {
    const newValue = parseInt(e.target.value);
    if (isNaN(newValue)) {
      setBubbleRadius(1);
    } else {
      if (newValue > maxRadiusLocal) {
        setBubbleRadius(maxRadiusLocal);
      } else {
        setBubbleRadius(newValue);
      }
    }
  };

  const handleNoteChange = (txt) => {
    try {
      setNote(txt);
    } catch (err) {
      console.log(err);
    }
  };

  const handleMarkerSubmit = async () => {
    try {
      await createMapMarker({
        channelId,
        coords: pinPosition,
        radius: !isNaN(bubbleRadius) && bubbleRadius > 1 ? bubbleRadius : null,
        note: note ? DOMPurify.sanitize(note) : "",
        class: selectedClass,
      });
      setNoteAdded("success");
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  useEffect(() => {
    if (coords) {
      setMapCenter(coords);
      setMapZoom(8);
    } else {
      setMapCenter({ lat: -23.698, lng: 133.8807 });
      setMapZoom(4);
    }
    setPinPosition(null);
    initMap();
  }, [coords, selectedClass]);

  useEffect(() => {
    if (!selectedMarker && handleMarkerClick) {
      handleMarkerClick(null);
    }
  }, selectedMarker);

  useEffect(() => {
    if (!pinPosition) {
      setBubbleRadius(null);
      setNoteAdded(false);
      setSelectedMarker(null);
    }
  }, [pinPosition]);

  useEffect(() => {
    if (maxRadius && !isNaN(maxRadius)) {
      setMaxRadiusLocal(maxRadius);
    }
  }, [maxRadius]);

  useEffect(() => {
    if (reloadMap) {
      initMap();
    }
  }, [reloadMap]);

  return (
    <div style={{ ...defaultContainerStyling, ...containerStyling }}>
      {!mapReady ? (
        <>
          {displayOnly ? null : (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: 4,
              }}
            >
              {pinPosition ? (
                <Button
                  color="warning"
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    setPinPosition(null);
                  }}
                >
                  Remove marker
                </Button>
              ) : (
                <Button
                  color="warning"
                  size="small"
                  variant="contained"
                  onClick={() => {
                    setPinPosition(mapCenter);
                  }}
                >
                  Add marker
                </Button>
              )}
              {pinPosition ? (
                <>
                  {bubbleRadius ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p
                        style={{ fontSize: 12, color: "grey", marginRight: 8 }}
                      >
                        Metres:
                      </p>
                      <TextField
                        size="small"
                        value={bubbleRadius}
                        onChange={handleBubbleChange}
                        sx={{
                          backgroundColor: "lightgrey",
                          borderRadius: "4px",
                          maxWidth: 100,
                        }}
                        inputProps={{
                          style: {
                            textAlign: "center",
                          },
                        }}
                      />
                      <IconButton
                        size="small"
                        onClick={() => {
                          setBubbleRadius(null);
                        }}
                      >
                        <CancelIcon sx={{ color: "grey" }} fontSize="small" />
                      </IconButton>
                    </div>
                  ) : (
                    <Button
                      color="info"
                      size="small"
                      variant="contained"
                      onClick={() => {
                        if (maxRadius) {
                          setBubbleRadius(maxRadius);
                        } else {
                          setBubbleRadius(1000);
                        }
                      }}
                    >
                      Add radius
                    </Button>
                  )}
                </>
              ) : (
                <>
                  {markerClasses ? (
                    <select
                      onChange={(e) => {
                        setSelectedClass(e.target.value);
                      }}
                    >
                      <option value="">Show all</option>
                      {markerClasses.map((el, i) => {
                        return (
                          <option key={`${i}-${el}`}>
                            {replaceAndSanitize(el.name)}
                          </option>
                        );
                      })}
                    </select>
                  ) : null}
                </>
              )}
            </div>
          )}
          <GoogleMap
            mapContainerStyle={{ ...defaultMapStyling, ...mapStyling }}
            center={mapCenter}
            zoom={mapZoom}
          >
            {pinPosition ? (
              <Marker
                position={pinPosition}
                clickable={true}
                draggable={true}
                onDragEnd={(e) => {
                  setPinPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() });
                }}
              />
            ) : null}
            {bubbleRadius ? (
              <Circle
                center={pinPosition}
                radius={bubbleRadius}
                options={{
                  fillColor: "blue",
                  fillOpacity: 0.3,
                  strokeColor: "black",
                  strokeOpacity: 1,
                  strokeWeight: 0.5,
                  clickable: false,
                  draggable: false,
                  geodesic: false,
                }}
              />
            ) : null}
            {Array.isArray(markers) && markers.length > 0 ? (
              <MarkerClusterer maxZoom={8}>
                {(clusterer) =>
                  markers.map((el, i) => {
                    return (
                      <>
                        <Marker
                          key={`${i}-${el._id}`}
                          clusterer={clusterer}
                          position={el.coords}
                          onClick={() => {
                            setSelectedMarker(el);
                            if (handleMarkerClick) {
                              handleMarkerClick(el);
                            }
                          }}
                        />
                        {el.radius ? (
                          <Circle
                            center={el.coords}
                            radius={el.radius}
                            options={{
                              fillColor: el.radiusColor
                                ? el.radiusColor
                                : "blue",
                              fillOpacity: 0.3,
                              strokeColor: "black",
                              strokeOpacity: 1,
                              strokeWeight: 0.5,
                              clickable: false,
                              draggable: false,
                              geodesic: false,
                            }}
                          />
                        ) : null}
                      </>
                    );
                  })
                }
              </MarkerClusterer>
            ) : null}
            {selectedMarker && selectedMarker.note ? (
              <InfoWindow
                position={selectedMarker.coords}
                onCloseClick={() => {
                  setSelectedMarker(null);
                }}
              >
                <p style={{ marginTop: 0, color: "black" }}>
                  {replaceAndSanitize(selectedMarker.note)}
                </p>
              </InfoWindow>
            ) : null}
          </GoogleMap>
          {pinPosition && acceptNotes ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                marginTop: 4,
                marginBottom: 2,
              }}
            >
              {noteAdded ? (
                <Button
                  size="small"
                  color="warning"
                  variant="outlined"
                  onClick={() => {
                    setNoteAdded(false);
                  }}
                >
                  Remove note
                </Button>
              ) : (
                <Button
                  size="small"
                  color="warning"
                  variant="contained"
                  onClick={() => {
                    setNoteAdded(true);
                  }}
                >
                  Add note
                </Button>
              )}
            </div>
          ) : null}
          {noteAdded === "success" ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h4 style={{ color: "limegreen" }}>Success!</h4>
              <Button variant="contained" size="small" onClick={initMap}>
                RELOAD MAP
              </Button>
            </div>
          ) : noteAdded === true ? (
            <GenericTextField
              buttonVariant="hidden"
              charLimit={254}
              divStyling={{ backgroundColor: "whitesmoke" }}
              rows={3}
              handleChange={handleNoteChange}
            />
          ) : null}

          <br></br>
          {pinPosition && !displayOnly && noteAdded !== "success" ? (
            <Button color="success" onClick={handleMarkerSubmit}>
              Submit marker
            </Button>
          ) : null}
        </>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}
