//  REVISIT: sanitize here?

import React, { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DOMPurify from "dompurify";

import CharTracker from "./CharTracker";
import { checkImage } from "../../functions/GeneralFunctions";

export default function GenericTextField({
  rows,
  divStyling,
  fieldStyling,
  charLimit,
  minChars,
  handleSubmit,
  handleChange,
  handleCancel,
  placeholder,
  buttonVariant,
  disabled,
  attachments,
}) {
  ////  INITS
  const defaultDivStyling = { borderRadius: 4 };

  ////  STATES
  const [textInput, setTextInput] = useState("");
  const [attachment, setAttachment] = useState(null);

  ////  FUNCTIONS
  const preSubmit = () => {
    try {
      if (textInput.length === 0) {
        alert("Field cannot be empty.");
        return;
      }

      if (minChars && textInput.length < minChars) {
        if (minChars === 1) {
          alert("Field cannot be empty.");
        } else {
          alert("Please enter at least " + minChars + " characters.");
        }
        return;
      }

      if (charLimit && textInput.length > charLimit) {
        alert("Input cannot exceed " + charLimit + " characters.");
        return;
      }

      if (attachments) {
        handleSubmit({
          text: DOMPurify.sanitize(textInput),
          file: attachment,
        });
      } else {
        handleSubmit(DOMPurify.sanitize(textInput));
      }
    } catch (err) {
      console.log(err);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    if (attachment) {
      const isValid = checkImage(attachment);
      if (!isValid.success) {
        alert(isValid.error);
        setAttachment(null);
      }
    }
  }, [attachment]);

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        {handleCancel ? (
          <Button size="small" variant="outlined" onClick={handleCancel}>
            CANCEL
          </Button>
        ) : (
          <div />
        )}
        <CharTracker
          chars={textInput.length}
          charLimit={charLimit ? charLimit : 9999}
        />
      </div>
      <div style={{ ...defaultDivStyling, ...divStyling }}>
        <TextField
          multiline={rows && rows > 1 ? true : false}
          fullWidth={true}
          rows={rows ? rows : 1}
          sx={fieldStyling}
          placeholder={placeholder ? placeholder : ""}
          onChange={(e) => {
            if (handleChange) {
              handleChange(e.target.value);
            }
            setTextInput(e.target.value);
          }}
        />
      </div>
      {buttonVariant === "hidden" ? null : (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 2,
          }}
        >
          {attachments ? (
            <input
              type="file"
              onChange={(e) => {
                setAttachment(e.target.files[0]);
              }}
            />
          ) : (
            <div />
          )}
          <Button
            variant={buttonVariant ? buttonVariant : "text"}
            onClick={preSubmit}
            disabled={disabled}
            size="small"
          >
            SUBMIT
          </Button>
        </div>
      )}
    </div>
  );
}
