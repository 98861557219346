import React, { useState, useEffect } from "react";

import GenericSidebar from "../common-components/GenericSidebar";
import { useWindowSize } from "../../utils/Hooks";
import MapSidebarTab from "./MapSidebarTab";
import StreamingSidebarTab from "./StreamingSidebarTab";
import PigeonHoleSidebarTab from "./PigeonHoleSidebarTab";
import LandingSidebarTab from "./LandingSidebarTab";
import TrackSidebarTab from "./TrackSidebarTab";
import CallConfigTab from "./CallConfigTab";
import SocketContainer from "./SocketContainer";

import { useDaily } from "@daily-co/daily-react";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import IconButton from "@mui/material/IconButton";

import "../../App.css";

export default function ConferenceSidebar({ isAdmin, callType }) {
  ////  INITS
  const daily = useDaily();
  const windowSize = useWindowSize();

  ////  STATES
  const [collapsed, setCollapsed] = useState(false);
  const [whichTab, setWhichTab] = useState("landing");
  const [meetingActive, setMeetingActive] = useState(false);
  const [streamingActive, setStreamingActive] = useState(false);
  const [streamInstanceId, setStreamInstanceId] = useState(null);

  ////  FUNCTIONS
  const toggleSidebar = () => {
    setCollapsed((prev) => !prev);
  };

  const toggleTab = (tab) => {
    setWhichTab(tab);
  };

  const handleLiveStreamingStarted = (e) => {
    try {
      if (e.instanceId) {
        setStreamingActive(true);
        setStreamInstanceId(e.instanceId);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleLiveStreamingStopped = (e) => {
    try {
      console.log(e);
      setStreamingActive(false);
      setStreamInstanceId(null);
    } catch (err) {
      console.log(err);
    }
  };

  const stopStreaming = () => {
    try {
      daily.stopLiveStreaming({
        instanceId: streamInstanceId,
      });
    } catch (err) {
      console.log(err);
      alert("Error terminating stream. You may need to disconnect manually.");
    }
  };

  const handleJoinedMeeting = (e) => {
    try {
      setMeetingActive(true);
    } catch (err) {
      console.log(err);
    }
  };

  ////  EFFECTS
  useEffect(() => {
    if (daily) {
      daily.on("live-streaming-started", handleLiveStreamingStarted);
      daily.on("live-streaming-stopped", handleLiveStreamingStopped);
      daily.on("joined-meeting", handleJoinedMeeting);

      return () => {
        daily.off("live-streaming-started", handleLiveStreamingStarted);
        daily.off("live-streaming-stopped", handleLiveStreamingStopped);
        daily.off("joined-meeting", handleJoinedMeeting);
      };
    }
  }, [daily]);

  return (
    <GenericSidebar
      windowSize={windowSize}
      styling={{ width: collapsed ? "5vw" : "30vw" }}
    >
      {collapsed ? (
        <IconButton
          onClick={() => {
            setCollapsed(false);
          }}
        >
          <MenuOpenIcon fontSize="large" sx={{ color: "lightgrey" }} />
        </IconButton>
      ) : (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          className="dark-container"
        >
          <select
            value={whichTab}
            onChange={(e) => {
              setWhichTab(e.target.value);
            }}
            style={{ width: "100%", margin: 10, fontSize: 16 }}
          >
            <option value="landing">Select feature</option>
            {isAdmin && callType === "broadcast" ? (
              <option value="streaming">Streaming</option>
            ) : null}
            {callType === "broadcast" ? (
              <option value="socket">Live Lobby</option>
            ) : null}
            {isAdmin && callType === "broadcast" ? (
              <option value="tracks">Tracks</option>
            ) : null}
            {isAdmin ? (
              <option value="call_config">Call configuration</option>
            ) : null}
            <option value="map">Interactive Map</option>
            <option value="pigeon_hole">Pigeon Hole</option>
          </select>
          {whichTab === "landing" ? (
            <LandingSidebarTab
              meetingActive={meetingActive}
              toggleSidebar={toggleSidebar}
            />
          ) : whichTab === "streaming" ? (
            <StreamingSidebarTab
              meetingActive={meetingActive}
              streamingActive={streamingActive}
              stopStreaming={stopStreaming}
              toggleTab={toggleTab}
            />
          ) : whichTab === "call_config" ? (
            <CallConfigTab />
          ) : whichTab === "socket" ? (
            <SocketContainer isAdmin={isAdmin} />
          ) : whichTab === "tracks" ? (
            <TrackSidebarTab />
          ) : whichTab === "map" ? (
            <MapSidebarTab />
          ) : whichTab === "pigeon_hole" ? (
            <PigeonHoleSidebarTab />
          ) : null}
        </div>
      )}
    </GenericSidebar>
  );
}

/*

*/
