import React, { useEffect, useState, useRef } from "react";

const AudioPlayer = ({ onTrackReady }) => {
  const [audioBuffer, setAudioBuffer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioContextRef = useRef(null);
  const sourceRef = useRef(null);
  const streamDestinationRef = useRef(null);

  useEffect(() => {
    audioContextRef.current = new (window.AudioContext ||
      window.webkitAudioContext)();
    streamDestinationRef.current =
      audioContextRef.current.createMediaStreamDestination();

    fetch("https://discoveryprovider.audius.co/v1/tracks/6xmzomM/stream")
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) =>
        audioContextRef.current.decodeAudioData(arrayBuffer)
      )
      .then((decodedBuffer) => {
        setAudioBuffer(decodedBuffer); // Save the decoded audio data
        // Notify parent or consumer that the track is ready
        onTrackReady(streamDestinationRef.current.stream);
      })
      .catch((error) =>
        console.error("Error fetching or decoding audio:", error)
      );
  }, [onTrackReady]);

  const playAudio = () => {
    if (audioBuffer && !isPlaying) {
      sourceRef.current = audioContextRef.current.createBufferSource();
      sourceRef.current.buffer = audioBuffer;
      sourceRef.current.connect(streamDestinationRef.current);
      sourceRef.current.connect(audioContextRef.current.destination); // Also play to speakers
      sourceRef.current.start(0);
      setIsPlaying(true);
    }
  };

  const pauseAudio = () => {
    if (sourceRef.current && isPlaying) {
      sourceRef.current.stop(0);
      setIsPlaying(false);
    }
  };

  return (
    <div>
      <h1>Audio Player</h1>
      <div>
        <button onClick={playAudio} disabled={isPlaying}>
          Play
        </button>
        <button onClick={pauseAudio} disabled={!isPlaying}>
          Pause
        </button>
      </div>
    </div>
  );
};

export default AudioPlayer;
