//  REVISIT: Handling Turnstile errors

import React, { useState, useRef, useEffect, useContext } from "react";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@mui/material/CircularProgress";
import Drawer from "@mui/material/Drawer";
import { Turnstile } from "@marsidev/react-turnstile";
import { useNavigate } from "react-router-dom";
import * as EmailValidator from "email-validator";

import { useWindowSize } from "../utils/Hooks";
import ScreenContainer from "../components/common-components/ScreenContainer";
import Navbar from "../components/common-components/Navbar";
import { electorateList } from "../utils/Data";
import {
  findElectorateFromPostcode,
  checkTurnstile,
  validateUsername,
  register,
} from "../functions/GeneralFunctions";
import { generateKeys } from "../functions/EncryptionFunctions";

import { GlobalContext } from "../App";

const textfieldStyles = {
  minWidth: 350,
  marginBottom: "10px",
};

export default function Register() {
  ////  INITS
  const navigate = useNavigate();
  const emailRef = useRef();
  const usernameRef = useRef();
  const electorateRef = useRef();
  const windowSize = useWindowSize();
  const GlobalCtxt = useContext(GlobalContext);

  const drawerTextStyles = {
    fontSize: windowSize.width > 768 ? 16 : 14,
  };

  ////  STATES
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [pwConfirm, setPwConfirm] = useState("");
  const [knowsElectorate, setKnowsElectorate] = useState(null);
  const [postcode, setPostcode] = useState("");
  const [electorateOptions, setElectorateOptions] = useState(null);
  const [checkingPostcode, setCheckingPostcode] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [turnstileToken, setTurnstileToken] = useState(null);

  ////  FUNCTIONS
  const handleRegister = async () => {
    try {
      setBtnDisabled(true);
      if (!turnstileToken) {
        alert("Please check the box to confirm you're not a bot.");
        setBtnDisabled(false);
        return;
      }
      if (!electorateList.includes(knowsElectorate)) {
        alert("Please enter a valid electorate.");
        setBtnDisabled(false);
        return;
      }
      if (password.length < 8) {
        alert("Password must consist of 8 characters or more.");
        setBtnDisabled(false);
        return;
      }
      if (password !== pwConfirm) {
        alert("Passwords don't match.");
        setBtnDisabled(false);
        return;
      }
      const isTurnstileValid = await checkTurnstile(turnstileToken);
      if (isTurnstileValid.data.success === false) {
        alert("Error verifying Turnstile checkbox.");
        setBtnDisabled(false);
        //  REVISIT: Programmatically reset Turnstile?
        return;
      }
      const trimmedEmail = emailRef.current.value.trim();
      const emailValid = EmailValidator.validate(trimmedEmail);
      if (!emailValid) {
        alert("Please enter a valid email address.");
        setBtnDisabled(false);
        return;
      }
      const trimmedUsername = usernameRef.current.value.trim();
      const usernameValid = validateUsername(trimmedUsername);
      if (!usernameValid) {
        alert(
          "Username must only contain letters, numbers, underscores and/or hyphens, and must not exceed 20 characters."
        );
        setBtnDisabled(false);
        return;
      }
      //const newId = new ObjectId();
      //const newIdStr = newId.toString();
      //const publicKey = await generateKeys(newIdStr);
      await register({
        email: trimmedEmail,
        username: trimmedUsername,
        password: password,
        electorate: knowsElectorate,
        //publicKey: publicKey,
        //_id: newIdStr,
      });
      navigate("/login/true");
    } catch (err) {
      console.log(err);
      alert(err.message);
      setBtnDisabled(false);
    }
  };

  const checkPostcode = async () => {
    try {
      setCheckingPostcode(true);
      const res = await findElectorateFromPostcode(postcode);
      if (res && Array.isArray(res)) {
        if (res.length > 1) {
          setElectorateOptions(res);
        } else {
          setKnowsElectorate(res[0].name);
        }
      } else {
        alert("Postcode not recognised!");
        resetPostcode();
      }
    } catch (err) {
      console.log(err);
      alert(err.message);
      resetPostcode();
    }
  };

  const resetPostcode = () => {
    setPostcode("");
    setCheckingPostcode(false);
  };

  ////  EFFECTS
  useEffect(() => {
    setTimeout(() => {
      setDrawerOpen(true);
    }, 1500);
  }, []);

  useEffect(() => {
    if (postcode) {
      if (postcode.length === 4) {
        checkPostcode();
      }
    }
  }, [postcode]);

  ////    ////    ////    ////
  return (
    <ScreenContainer>
      <Navbar />
      <Drawer
        anchor="bottom"
        open={drawerOpen}
        transitionDuration={1000}
        onClose={() => {
          setDrawerOpen((el) => !el);
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            minHeight: "50vh",
            padding: "4px 8px 24px 8px",
            boxSizing: "border-box",
          }}
        >
          <div style={{ width: "100%", maxWidth: 600 }}>
            <h3 style={{ textAlign: "center" }}>A quick heads up ...</h3>
            <p style={drawerTextStyles}>
              This website's encrypted messaging feature can only be used on{" "}
              <span style={{ fontWeight: 600 }}>one device per account.</span>{" "}
              If you register on your computer, the messaging feature will not
              be available on your phone (and vice versa).
            </p>
            <p style={drawerTextStyles}>
              We encourage users to register on their{" "}
              <span style={{ fontWeight: 600 }}>
                laptop or desktop computer.
              </span>{" "}
              Larger devices offer a better user experience, particularly when
              it comes to video conferencing.
            </p>
            <p style={drawerTextStyles}>
              All other features can be accessed on multiple devices, regardless
              of how you register. If you want to send messages from two
              different devices,{" "}
              <span style={{ fontWeight: 600 }}>
                you're welcome to create a second account.
              </span>
            </p>
            <p style={drawerTextStyles}>
              If you don't intend to use the encrypted messaging feature, feel
              free to disregard this message.
            </p>
            <h4 style={{ textAlign: "center" }}>See you inside!</h4>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => {
                  setDrawerOpen(false);
                }}
                variant="contained"
              >
                PROCEED
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "98vw",
          maxWidth: 600,
          padding: 5,
          backgroundColor: "whitesmoke",
          border: "2px solid black",
          boxShadow: GlobalCtxt.genericBoxShadow,
        }}
      >
        <h3>Welcome aboard!</h3>
        <TextField
          placeholder="Username"
          sx={textfieldStyles}
          inputRef={usernameRef}
          size="small"
        />
        <TextField
          placeholder="Email"
          sx={textfieldStyles}
          inputRef={emailRef}
          size="small"
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            placeholder="Password"
            type={showPassword ? null : "password"}
            value={password}
            sx={{ minWidth: 300 }}
            size="small"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <div style={{ display: "flex", justifyContent: "center", width: 50 }}>
            <IconButton
              onClick={() => {
                setShowPassword((prev) => !prev);
              }}
            >
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </div>
        </div>
        <div
          style={{
            minWidth: 350,
            height: password.length >= 8 ? "10px" : null,
          }}
        >
          {password.length < 8 ? (
            <p style={{ fontSize: 10, margin: "2px 2px 8px 2px" }}>
              Minimum 8 characters.
            </p>
          ) : null}
        </div>
        <TextField
          placeholder="Confirm password"
          type="password"
          value={pwConfirm}
          sx={textfieldStyles}
          size="small"
          onChange={(e) => {
            setPwConfirm(e.target.value);
          }}
        />
        <div
          style={{
            minWidth: 350,
            height: password.length >= 8 ? "10px" : null,
          }}
        >
          {pwConfirm !== password ? (
            <p style={{ fontSize: 10, margin: "2px 2px 8px 2px" }}>
              Must match password.
            </p>
          ) : null}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {knowsElectorate === null ? (
            <>
              <p style={{ fontSize: 14, marginRight: 8 }}>
                Do you know your federal electorate?
              </p>
              <select
                onChange={(e) => {
                  setKnowsElectorate(e.target.value);
                }}
              >
                <option value={null}></option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
            </>
          ) : knowsElectorate === true || knowsElectorate === "true" ? (
            <>
              <p style={{ fontSize: 14, marginRight: 8 }}>
                Select your electorate:
              </p>
              <select onChange={(e) => setKnowsElectorate(e.target.value)}>
                <option value={null}></option>
                {electorateList.map((el, i) => {
                  return (
                    <option value={el} key={`${i}-${el}`}>
                      {el}
                    </option>
                  );
                })}
              </select>
            </>
          ) : knowsElectorate === false || knowsElectorate === "false" ? (
            <>
              {electorateOptions && Array.isArray(electorateOptions) ? (
                <>
                  {electorateOptions.map((el, i) => {
                    return (
                      <Button
                        key={i}
                        sx={{ marginRight: "8px" }}
                        variant="outlined"
                        onClick={() => setKnowsElectorate(el.name)}
                      >
                        {el.name}
                      </Button>
                    );
                  })}
                </>
              ) : (
                <>
                  <p
                    style={{
                      fontSize: 14,
                      marginRight: 8,
                    }}
                  >
                    Enter your postcode:
                  </p>
                  {checkingPostcode === false ? (
                    <TextField
                      size="small"
                      sx={{ width: 100 }}
                      inputProps={{
                        style: { textAlign: "center" },
                      }}
                      onChange={(e) => {
                        setPostcode(e.target.value);
                      }}
                    />
                  ) : (
                    <CircularProgress />
                  )}
                </>
              )}
            </>
          ) : (
            <h4>{knowsElectorate}</h4>
          )}
        </div>
        <br></br>
        <Turnstile
          siteKey={process.env.REACT_APP_CF_TESTPASS}
          onSuccess={setTurnstileToken}
          onError={() => {
            alert(
              "We've detected potentially suspicious user activity. If you think this is a mistake, please refresh the page and try again."
            );
          }}
        />
        <br></br>
        <Button
          variant="contained"
          disabled={btnDisabled}
          onClick={handleRegister}
        >
          REGISTER!
        </Button>
      </div>
    </ScreenContainer>
  );
}
