import React, { useContext } from "react";

import { useNavigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";

import "animate.css";

import { GlobalContext } from "../../App";

export default function LobbyDiv({ children }) {
  ////  INITS
  const GlobalCtxt = useContext(GlobalContext);
  const navigate = useNavigate();
  const params = useParams();

  return (
    <div
      style={{
        maxHeight: "90vh",
        width: "100vw",
        maxWidth: 768,
        backgroundColor: "rgba(255,255,255,0.85)",
        padding: 10,
        boxSizing: "border-box",
        overflowY: "auto",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {children}
        <Button
          sx={{ marginTop: "8px" }}
          onClick={() =>
            navigate(
              `/home/${params.channelId}/${decodeURIComponent(
                params.channelName
              )}`
            )
          }
        >
          RETURN TO HOMEPAGE
        </Button>
      </div>
    </div>
  );
}
